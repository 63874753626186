import { DBFactory } from "classes/database/db_factory";
import { Observable, map, of, switchMap } from "rxjs";
import { ClassroomEmailInfo } from "./classroom-email-info.model";
import { ClassroomEmail } from "./classroom-email.model";

export class ClassroomEmailsService {
  static async get(id: string, loadInfo: boolean = false) {
    const db = DBFactory.createDatabase();
    const data = await db.get({
      collection: "/classroomEmails",
      path: `/classroomEmails/${id}`,
    });

    if (loadInfo) {
      data["info"] = await db.get({
        collection: `/classroomEmails/${id}/info/${id}`,
        path: `/classroomEmails/${id}/info/${id}`,
      });
    }

    return ClassroomEmail.fromMap(data);
  }

  static streamClassroomEmail(
    id: string,
    loadInfo: boolean = false
  ): Observable<ClassroomEmail> {
    const db = DBFactory.createDatabase();
    return db
      .streamRecord({
        collection: "/classroomEmails",
        path: `/classroomEmails/${id}`,
      })
      .pipe(
        switchMap((classroomEmailData) => {
          if (!loadInfo || !classroomEmailData) {
            // Return an Observable that emits classroomEmailData
            return of(classroomEmailData);
          }

          return db
            .streamRecord({
              collection: `/classroomEmails/${id}/info/${id}`,
              path: `/classroomEmails/${id}/info/${id}`,
            })
            .pipe(
              map((infoData) => {
                classroomEmailData["info"] = infoData;
                return classroomEmailData;
              })
            );
        }),
        map((data) => ClassroomEmail.fromMap(data))
      );
  }

  static streamEmailInfo(id: string): Observable<ClassroomEmailInfo> {
    const db = DBFactory.createDatabase();
    return db
      .streamRecord({
        collection: `/classroomEmails/${id}/info/${id}`,
        path: `/classroomEmails/${id}/info/${id}`,
      })
      .pipe(map((data) => ClassroomEmailInfo.fromMap(data)));
  }

  static async list(userId: string, params?: { includeInfo: boolean }) {
    const db = DBFactory.createDatabase();
    const data = await db.list(
      {
        collection: "/classroomEmails",
        path: "/classroomEmails",
      },
      [
        {
          field: "userId",
          operator: "==",
          value: userId,
          type: "where",
        },
        {
          type: "orderBy",
          field: "createdAtTimestamp",
          direction: "desc",
        },
      ]
    );

    const classroomEmails = data.map((d) => ClassroomEmail.fromMap(d));

    if (params?.includeInfo) {
      for (const email of classroomEmails) {
        const infoData = await db.get({
          collection: `/classroomEmails/${email.id}/info/${email.id}`,
          path: `/classroomEmails/${email.id}/info/${email.id}`,
        });

        email.info = ClassroomEmailInfo.fromMap(infoData);
      }
    }

    return classroomEmails;
  }

  static stream({
    userId,
    classroomId,
  }: {
    userId: string;
    classroomId?: string;
  }) {
    const queryParams = [
      {
        field: "userId",
        operator: "==",
        value: userId,
        type: "where",
      },
    ] as ModelQueryConfig;

    if (classroomId) {
      queryParams.push({
        field: "classroomId",
        operator: "==",
        value: classroomId,
        type: "where",
      });
    }

    queryParams.push({
      type: "orderBy",
      field: "createdAtTimestamp",
      direction: "desc",
    });

    const db = DBFactory.createDatabase();
    return db
      .streamList(
        {
          collection: "/classroomEmails",
          path: "/classroomEmails",
        },
        queryParams
      )
      .pipe(
        map((data) =>
          data
            .map((d) => ClassroomEmail.fromMap(d))
            .filter((email) => email.isArchived != true)
        )
      );
  }
}
